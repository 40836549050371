import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
  selector: 'app-documentdel',
  templateUrl: './documentdel.component.html',
  styleUrls: ['./documentdel.component.css']
})
export class DocumentdelComponent implements OnInit {

  constructor(public dialogo: MatDialogRef<DocumentdelComponent>,
    @Inject(MAT_DIALOG_DATA) public mensaje: string) { }

    closeDialog(): void {
      this.dialogo.close(false);
    }
    confirmDialog(): void {
      this.dialogo.close(true);
    }

    ngOnInit(): void {
    }

}
