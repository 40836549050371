import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../_helpers/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('src/app/components/login/login.module').then(
        (m) => m.LoginModule
      ),
  },
  {
    path: 'temp-login-access',
    loadChildren: () =>
      import('src/app/components/login/templogin.module').then(
        (m) => m.LoginModule
      ),
  },
  {
    path: 'recuperacontra',
    loadChildren: () =>
      import('src/app/components/recuperacontra/recuperacontra.module').then(
        (m) => m.RecuperacontraModule
      ),
  },
  {
    path: 'registro',
    loadChildren: () =>
      import('src/app/components/registro/registro.module').then(
        (m) => m.RegistroModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('src/app/components/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'verificacion',
    loadChildren: () =>
      import(
        'src/app/components/registro/verificacion/verificacion.module'
      ).then((m) => m.VerificacionModule),
  },
  {
    path: 'registro-proveedor',
    loadChildren: () =>
      import(
        'src/app/components/registro/vendor-register/vendor-register.module'
      ).then((m) => m.VendorRegisterModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'registro-pago',
    loadChildren: () =>
      import(
        'src/app/components/registro/pay-register/pay-register.module'
      ).then((m) => m.PayRegisterModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dialogs',
    loadChildren: () =>
      import('src/app/components/dialogs/dialogs.module').then(
        (m) => m.DialogsModule
      ),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [FlexLayoutModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
